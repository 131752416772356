import { ChannelModel } from "../../../models/ChannelModel";
import { ChannelType } from "../ChannelProvider";

export const BCAVAFlip: ChannelModel = {
  thumbnail: "bca sprite",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  code: "bca",
  title: "BCA",
  subtitle: "BCA Virtual Account",
  vendor: "Rumah Yatim",
  hows: [
    {
      name: "ATM BCA",
      steps: [
        "Pada menu utama, pilih Transaksi Lainnya.",
        "Pilih Transfer.",
        "Pilih Ke Rek BCA Virtual Account.",
        "Masukkan Nomor Rekening pembayaran (11 digit) Anda lalu tekan Benar.",
        "Masukkan jumlah tagihan yang akan anda bayar.",
        "Pada halaman konfirmasi transfer akan muncul detail pembayaran Anda. Jika informasi telah sesuai tekan Ya.",
      ],
    },
    {
      name: "Klik BCA",
      steps: [
        "Pilih menu Transfer Dana.",
        "Pilih Transfer ke BCA Virtual Account.",
        "Masukkan nomor BCA Virtual Account, atau pilih Dari Daftar Transfer.",
        "Jumlah yang akan ditransfer, nomor rekening dan nama merchant akan muncul di halaman konfirmasi pembayaran, jika informasi benar klik Lanjutkan.",
        "Ambil BCA Token Anda dan masukkan KEYBCA Response APPLI 1 dan Klik Submit.",
        "Transaksi Anda selesai.",
      ],
    },
    {
      name: "m-BCA",
      steps: [
        "Lakukan log in pada aplikasi BCA Mobile.",
        "Pilih menu m-BCA, kemudian masukkan kode akses m-BCA.",
        "Pilih m-Transfer > BCA Virtual Account.",
        "Pilih dari Daftar Transfer, atau masukkan Nomor Virtual Account tujuan.",
        "Masukkan jumlah yang ingin dibayarkan.",
        "Masukkan pin m-BCA.",
        "Pembayaran selesai. Simpan notifikasi yang muncul sebagai bukti pembayaran.",
      ],
    },
  ],
};

export const BSIVAFlip: ChannelModel = {
  thumbnail: "bsi sprite",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  code: "bsm",
  title: "BSI (Bank Syariah Indonesia)",
  subtitle: "BSI Virtual Account",
  hows: [
    {
      name: "ATM BSI",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BSI Virtual Account”",
        "Masukkan nomor BSI Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BSI Pribadi",
      steps: [
        "Silahkan login pada aplikasi KlikBSI Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke BSI Virtual Account”",
        "Masukkan nomor BSI Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYBCA APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BSI Bisnis",
      steps: [
        "Silahkan melakukan login di KlikBSI Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BSI Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BSI Virtual Account”",
        "Pilih rekening sumber dana dan BSI Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BSI",
      steps: [
        "Silahkan login pada aplikasi BSI Mobile",
        "Pilih “m-BSI, lalu masukkan kode akses m-BSI",
        "Pilih “m-Transfer”",
        "Lanjut ke “BSI Virtual Account”",
        "Masukkan nomor BSI Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BSI Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const MandiriVAFlip: ChannelModel = {
  thumbnail: "mandiri sprite",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  code: "mandiri",
  title: "Mandiri",
  subtitle: "Mandiri Virtual Account",
  hows: [
    {
      name: "ATM Mandiri",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening Mandiri Virtual Account”",
        "Masukkan nomor Mandiri Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik Mandiri Pribadi",
      steps: [
        "Silahkan login pada aplikasi KlikMandiri Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke Mandiri Virtual Account”",
        "Masukkan nomor Mandiri Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYMandiri APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik Mandiri Bisnis",
      steps: [
        "Silahkan melakukan login di KlikMandiri Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor Mandiri Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke Mandiri Virtual Account”",
        "Pilih rekening sumber dana dan Mandiri Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-Mandiri",
      steps: [
        "Silahkan login pada aplikasi Mandiri Mobile",
        "Pilih “m-Mandiri, lalu masukkan kode akses m-Mandiri",
        "Pilih “m-Transfer”",
        "Lanjut ke Mandiri Virtual Account”",
        "Masukkan nomor Mandiri Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BSI Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};

export const BNIVAFlip: ChannelModel = {
  thumbnail: "bni sprite",
  type: ChannelType.VIRTUAL_ACCOUNT,
  payment_type: "virtual_account",
  code: "bni",
  title: "BNI",
  subtitle: "BNI Virtual Account",
  hows: [
    {
      name: "ATM BNI",
      steps: [
        "Masukkan kartu ke mesin ATM",
        "Masukkan 6 digit PIN Anda",
        "Pilih “Transaksi Lainnya”",
        "Pilih “Transfer”",
        "Lanjut ke “ke Rekening BNI Virtual Account”",
        "Masukkan nomor BNI Virtual Account Anda, kemudian tekan “Benar”",
        "Masukkan jumlah yang akan dibayarkan, selanjutnya tekan “Benar”",
        "Validasi pembayaran Anda. Pastikan semua detail transaksi yang ditampilkan sudah benar, kemudian pilih “Ya” dan lanjutkan transaksi sampai selesai",
      ],
    },
    {
      name: "Klik BNI Pribadi",
      steps: [
        "Silahkan login pada aplikasi BNI Individual",
        "Masukkan User ID dan PIN Anda",
        "Pilih “Transfer Dana”",
        "Pilih “Transfer ke BNI Virtual Account”",
        "Masukkan nomor BNI Virtual Account Anda",
        "Masukkan jumlah yang akan dibayarkan",
        "Validasi pembayaran. Pastikan semua datanya sudah benar, lalu masukkan kode yang diperoleh dari KEYMandiri APPLI 1, kemudian klik “Kirim”",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "Klik BNI Bisnis",
      steps: [
        "Silahkan melakukan login di BNI Bisnis",
        "Pilih “Transfer Dana” > “Daftar Transfer” > “Tambah”",
        "Masukkan nomor BNI Virtual Account, lalu “Kirim”",
        "Pilih “Transfer Dana”",
        "Lanjut ke “Ke BNI Virtual Account”",
        "Pilih rekening sumber dana dan BNI Virtual Account tujuan",
        "Lalu, masukkan jumlah yang akan dibayarkan, lalu pilih “Kirim”",
        "Validasi Pembayaran. Sampai tahap ini berarti data berhasil di input. Kemudian pilih “simpan”",
        "Pilih “Transfer Dana” > “Otorisasi Transaksi”, lalu pilih transaksi yang akan diotorisasi",
        "Pembayaran telah selesai dilakukan",
      ],
    },
    {
      name: "m-BNI",
      steps: [
        "Silahkan login pada aplikasi BNI Mobile",
        "Pilih “m-BNI, lalu masukkan kode akses m-BNI",
        "Pilih “m-Transfer”",
        "Lanjut ke BNI Virtual Account”",
        "Masukkan nomor BNI Virtual Account Anda",
        "Lalu, masukkan jumlah yang akan dibayarkan",
        "Masukkan PIN m-BNI Anda",
        "Transaksi telah berhasil",
      ],
    },
  ],
};
